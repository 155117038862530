import AthenaTextInput from 'athena/components/text-input';
import AthenaTextarea from 'athena/components/textarea';
import t from 'react-translate';

const ProgramNameAndDescription = () => (
  <div className='mb-6'>
    <div className='heading-5 mt-4 mb-2'>
      {t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.NAME_DESCRIPTION()}
    </div>
    <AthenaTextInput
      withForm
      required
      name='name'
      className='mb-2'
      placeholder={t.MENTORING_PROGRAMS.PROGRAM_NAME_LABEL()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
    />
    <AthenaTextarea
      withForm
      name='description'
      placeholder={t.MENTORING_PROGRAMS.DESCRIPTION_LABEL()}
    />
  </div>
);

export default ProgramNameAndDescription;
