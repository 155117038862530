import extend from 'lodash/extend';
import moment from 'moment';
import tinycolor from 'tinycolor2';
import t from 'react-translate';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { ReviewStatus } from 'practice_room/components/shared/submission/request-review-cta';

export const readFileAsDataURL = (file: File) => (
  new Promise<string>((res, rej) => {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      res(fileReader.result.toString());
    };

    fileReader.onerror = rej;

    fileReader.readAsDataURL(file);
  })
);

export const objectToFormData = (obj, form?: FormData, namespace?: string) => {
  const fd = form || new FormData();
  let formKey;

  if (Array.isArray(obj) ? obj.length : true) {
    // eslint-disable-next-line no-restricted-syntax
    for (const property in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        if (namespace) {
          formKey = `${namespace}[${property}]`;
        } else {
          formKey = property;
        }

        if (
          typeof obj[property] === 'object'
          && obj[property] !== null
          && !(obj[property] instanceof File)
        ) {
          objectToFormData(obj[property], fd, formKey);
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
  } else {
    fd.append(namespace, '[]');
  }

  return fd;
};

export const deepSet = (obj, path, value) => {
  const paths = path.split('.');
  const lastProperty = paths.pop();
  let current = obj;

  for (let i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    }
    current = current[paths[i]];
  }

  current[lastProperty] = value;
};

export type TextMeasurerTextStyles = {
  fontSize: number,
  fontFamily: string,
  lineHeight?: number,
  fontWeight?: number,
  fontVariant?: 'normal' | 'small-caps',
  fontStyle?: 'normal' | 'italic' | 'oblique',
  // Properties that CanvasRenderingContext2D doesn't support
  letterSpacing?: number,
  textTransform?: 'none' | 'lowercase' | 'uppercase' | 'capitalize',
};
export class TextMeasurer {
  _ctx: CanvasRenderingContext2D;

  _textStyles: TextMeasurerTextStyles;

  constructor(textStyles: TextMeasurerTextStyles) {
    this._textStyles = {
      letterSpacing: 0,
      textTransform: 'none',
    } as TextMeasurerTextStyles;

    extend(this._textStyles, textStyles);

    this._ctx = document.createElement('canvas').getContext('2d');

    const {
      fontStyle,
      fontVariant,
      fontWeight,
      fontSize,
      lineHeight,
      fontFamily,
    } = this._textStyles;

    // Setting here the font property to measure the text with the styles
    // provided, it should be the exact format the css font property value is
    this._ctx.font = [
      fontStyle,
      fontVariant,
      fontWeight,
      this._getFontSizeComponent(fontSize, lineHeight),
      fontFamily,
    ].filter(Boolean).join(' ');
  }

  /**
   * Function that converts the fontSize and lineHeight value into the format
   * that css property should be.
   * For example, if fontSize is 12 and lineHeight is 15 the result will be
   * "12px/15px" but if only fontSize is present, the result will be "12px"
   */
  _getFontSizeComponent(fontSize: number, lineHeight?: number) {
    // eslint-disable-next-line consistent-return
    const getSuffixedString = (number: number) => {
      if (number) {
        return `${number}px`;
      }
    };

    const fontSizeSuffixed = getSuffixedString(fontSize);
    const lineHeightSuffixed = getSuffixedString(lineHeight);

    return (lineHeight === undefined) ? fontSizeSuffixed : `${fontSizeSuffixed}/${lineHeightSuffixed}`;
  }

  _toLowercase(text: string) {
    return text.toLowerCase();
  }

  _toUppercase(text: string) {
    return text.toUpperCase();
  }

  _capitalize(text: string) {
    const separator = ' ';

    return text.split(separator).map((word) => {
      if (word.length) {
        return `${word.charAt(0).toUpperCase()}${word.substring(1, word.length)}`;
      }

      return word;
    }).join(separator);
  }

  measureText(text: string) {
    const textToMeasure = (() => {
      switch (this._textStyles.textTransform) {
        case 'none':
          return text;
        case 'lowercase':
          return this._toLowercase(text);
        case 'uppercase':
          return this._toUppercase(text);
        case 'capitalize':
          return this._capitalize(text);
        default:
          throw new Error(`Unhandled "textTransform" value: ${this._textStyles.textTransform}`);
      }
    })();

    const measuredText = this._ctx.measureText(textToMeasure);

    return measuredText.width + (text.length * this._textStyles.letterSpacing);
  }
}

export const mockRequest = <T extends unknown>(mockedResponse: T, duration: number) => new Promise<T>(
  (resolve) => setTimeout(() => resolve(mockedResponse), duration),
);

/**
 * Calculates the time elapsed since a given date.
 *
 * @param date - The date to calculate the time since.
 * @param isLight - Optional parameter to indicate if the result should be in a light format (with/without the prefix "Last Updated at:" ).
 * @returns The time elapsed since the given date, formatted according to the provided options.
 */
export const timeSince = (date: string, isLight = false) => {
  const current = moment();
  const since = moment(date);

  const months = current.diff(since, 'months');
  const days = current.diff(since, 'days');
  const hours = current.diff(since, 'hours');
  const minutes = current.diff(since, 'minutes');
  const seconds = current.diff(since, 'seconds');

  if (months > 0) {
    if (isLight) return t.TIME_SINCE.LIGHT_MONTHS_AGO(months);
    return t.TIME_SINCE.MONTHS_AGO(months);
  } if (days > 0) {
    if (isLight) return t.TIME_SINCE.LIGHT_DAYS_AGO(days);
    return t.TIME_SINCE.DAYS_AGO(days);
  } if (hours > 0) {
    if (isLight) return t.TIME_SINCE.LIGHT_HOURS_AGO(hours);
    return t.TIME_SINCE.HOURS_AGO(hours);
  } if (minutes > 0) {
    if (isLight) return t.TIME_SINCE.LIGHT_MINUTES_AGO(minutes);
    return t.TIME_SINCE.MINUTES_AGO(minutes);
  } if (seconds > 0) {
    if (isLight) return t.TIME_SINCE.LIGHT_LESS_THAN_MINUTE_AGO();
    return t.TIME_SINCE.LESS_THAN_MINUTE_AGO();
  }

  return null;
};

export const getDateText = (date) => {
  let visitedWorkspaceAtText = '';
  if (date) {
    const visitedWorkspaceAtMoment = moment(date);
    const currentMoment = moment();

    if (currentMoment.isSame(visitedWorkspaceAtMoment, 'd')) {
      visitedWorkspaceAtText = visitedWorkspaceAtMoment.format('LT');
    } else if (currentMoment.isSame(visitedWorkspaceAtMoment, 'year')) {
      visitedWorkspaceAtText = visitedWorkspaceAtMoment.format('MOMENT.MONTH_DAY');
    } else {
      visitedWorkspaceAtText = visitedWorkspaceAtMoment.format('MOMENT.MONTH_DAY_YY');
    }
  }
  return visitedWorkspaceAtText;
};

type FrameAnimationInfo = {
  progress: number,
  status: 'stopped' | 'finished',
};

export type FrameAnimation = {
  stopAnimation: Function,
  animationPromise: Promise<FrameAnimationInfo>
};

type UpdateFunction = (duration: number, progress: number) => void;

export const animate = (duration: number, update: UpdateFunction): (() => FrameAnimation) => {
  let start;
  let progress;
  let promiseResolve;
  let isPlaying = false;

  const animationPromise = new Promise<FrameAnimationInfo>((resolve) => {
    promiseResolve = resolve;
  });

  const step = (timestamp) => {
    if (!start) start = timestamp;

    progress = Math.min(timestamp - start, duration);

    update(duration, progress);

    if (timestamp - start <= duration && isPlaying) {
      requestAnimationFrame(step);
    } else {
      isPlaying = false;

      promiseResolve({
        progress,
        status: progress >= duration ? 'finished' : 'stopped',
      });
    }
  };

  const stopAnimation = () => {
    isPlaying = false;
  };

  const startAnimation = (): FrameAnimation => {
    isPlaying = true;
    requestAnimationFrame(step);

    return {
      stopAnimation,
      animationPromise,
    };
  };

  return startAnimation;
};

function isScrollParent(element: HTMLElement): boolean {
  const { overflow, overflowX, overflowY } = window.getComputedStyle(element);
  return /auto|scroll|overlay|hidden/.test(overflow + overflowY + overflowX);
}

/* @ngInject */
export function getScrollParent(node: HTMLElement): HTMLElement {
  if (['html', 'body', '#document'].indexOf(node.nodeName.toLowerCase()) >= 0) {
    return node.ownerDocument.body;
  }

  if (isScrollParent(node)) {
    return node;
  }

  return getScrollParent(node.parentElement);
}

/* @ngInject */
export function getFocusableParent(node?: HTMLElement): HTMLElement {
  if (!node) return null;

  if (node.tabIndex !== -1) {
    return node;
  }

  return getFocusableParent(node.parentElement);
}

/* @ngInject */
export function gtag(params) {
  if (window.dataLayer) {
    window.dataLayer.push(params);
  }
}

/* @ngInject */
export function resetGoogleAnalytics() {
  window.dataLayer.push(function () {
    this.reset();
  });
}

/* @ngInject */
export function insertGoogleAnalytics(gid) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  const src = `https://www.googletagmanager.com/gtm.js?id=${gid}`;
  const script = document.querySelector(`script[src="${src}"]`);
  if (!script) {
    window.gtag = gtag;
    const gaScript = document.createElement('script');
    gaScript.src = src;
    document.head.appendChild(gaScript);
  }
}

/* @ngInject */
export function getOffsetTopRelativeToSpecificParent(
  node,
  offsetParent,
  accumulated = 0,
) {
  if (window.getComputedStyle(offsetParent).position !== 'static') {
    if (offsetParent.contains(node)) {
      if (node.offsetParent !== offsetParent) {
        return getOffsetTopRelativeToSpecificParent(
          node.offsetParent,
          offsetParent,
          accumulated + node.offsetTop,
        );
      }

      return accumulated + node.offsetTop;
    }

    return accumulated;
  }

  throw new Error("Can't calculate offset to specific container");
}

export const getSanitizedStyles = (styles) => {
  const sanitizedStyles = [];
  styles.forEach(style => {
    if (style) sanitizedStyles.push(style);
  });
  return sanitizedStyles;
};

export const uploadFileOptions = {
  slides: {
    backendName: 'slides',
    translationString: 'LECTURE_VIDEO.DOCUMENT_TYPE_SLIDES',
    type: 'presentation',
  },
  transcript: {
    backendName: 'textTranscript',
    translationString: 'LECTURE_VIDEO.DOCUMENT_TYPE_TRANSCRIPT',
    type: 'document',
  },
  customThumbnail: {
    backendName: 'customThumbnail',
    translationString: 'LECTURE_VIDEO.DOCUMENT_TYPE_CUSTOM_THUMBNAIL',
    type: 'image',
  },
  captions: {
    backendName: 'transcript',
    translationString: 'LECTURE_VIDEO.DOCUMENT_TYPE_CAPTIONS',
    type: 'subtitles',
  },
};

export const isSameColor = (color1, color2) => tinycolor.equals(color1, color2);

// this popper modifier resizes the dropdown so it doesn't overflow its parent.
// It also adds a bottom margin (using the halfspacing constant)
export const getApplyMaxSize = (maxSizeInterceptor?, addScroll?) => ({
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite' as const,
  requires: ['maxSize'],
  fn({ state }) {
    const heightWithDefaultMargin = state.modifiersData.maxSize.height - halfSpacing;
    state.styles.popper.maxHeight = `${maxSizeInterceptor?.(heightWithDefaultMargin) || heightWithDefaultMargin}px`;
    if (addScroll) {
      state.styles.popper.overflow = 'auto';
    }
  },
});

/**
 * Check if two objects with arrays are deeply unequal
 *
 * @param prevValue - The previous object with arrays
 * @param currValue - The current object with arrays to compare
 * @returns True if the objects with arrays are deeply unequal, false otherwise
 *
 * @example
 * const prevValue = { learningTypes: ['course'], skillTagIds: [200, 201] };
 * const currValue = { learningTypes: ['journey'], skillTagIds: [200] };
 */
/* @ngInject */
export function areObjectsModified<T>(
  prevValue: Record<string, Array<T>>,
  currValue: Record<string, Array<T>>,
): boolean {
  // Check if both objects are empty
  if (Object.keys(prevValue).length === 0 && Object.keys(currValue).length === 0) {
    return false;
  }

  // Check if the prev objects is empty
  if (Object.keys(prevValue).length === 0 && Object.keys(currValue).length > 0) {
    return true;
  }

  return Object.keys(prevValue).some(key => {
    const prevKey = prevValue[key];
    const currKey = currValue[key];
    return JSON.stringify(prevKey) !== JSON.stringify(currKey);
  });
}

export const roundBasedOnDecimal = (number) => {
  const integerPart = Math.floor(number);
  const decimalPart = number - integerPart;

  if (decimalPart > 0.5) {
    return Math.ceil(number);
  }
  return Math.floor(number);
};
export const extractTextFromHTML = (html: string) => (html ? html.replace(/<[^>]*>/g, '') : '');


export const getInitials = (user: any) => {
  if (!user) {
    return '';
  }

  return user.initials ?? (user?.firstName[0]?.toUpperCase() ?? '') + (user?.lastName[0]?.toUpperCase() ?? '');
};

export const showRequestReviewCTAConfig = (reviewStatus: ReviewStatus, isViewerMentor?: boolean) => {
  if (reviewStatus === ReviewStatus.NO_APPROVAL && !isViewerMentor) {
    return {
      label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.SUBMIT_REVIEW_CTA(),
      disabled: false,
      tooltip: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REQUEST_CTA_TOOLTIP(),
    };
  }
  if (reviewStatus === ReviewStatus.REQUESTED) {
    return {
      label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.PENDING_REVIEW_CTA(),
      disabled: true,
      tooltip: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.PENDING_CTA_TOOLTIP(),
    };
  }
  if (reviewStatus === ReviewStatus.REVIEWED) {
    return {
      label: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.REVIEW_RECEIVED_CTA(),
      disabled: false,
      className: 'review-tag-active',
      tooltip: t.PRACTICE_ROOM.INSIGHTS.REQUEST_REVIEW.RECEIVED_CTA_TOOLTIP(),
    };
  }
  return undefined;
};
