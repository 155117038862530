import { css } from '@emotion/react';
import { useRef, useState, useEffect } from 'react';
import NvTooltip from './nv-tooltip';

type TruncatedTextProps = {
  text: string,
  className: string,
  textWidth?: number,
  linesCount?: number,
};
/**
 * @param text text to truncate
 * @param className classname of the text
 * @param textWidth the width of the container that expects the overflow of text 
 * @param linesCount pass only if the text need to display on multi lines
 * @returns jsx element
 */

const NvTruncatedTextWithTooltip = ({
  text,
  className,
  textWidth,
  linesCount,
}: TruncatedTextProps) => {
  const overflowingText = useRef(null);
  const [isOverflowActive, setOverflowActive] = useState(false);
  const [isSingleline, setIsSingleLine] = useState(false);

  const styles = css`
    ${linesCount && `
    -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: ${linesCount};
      height: calc(${linesCount * 1.5}em + 5px);
    `}
    ${isSingleline && linesCount && `
      display: flex !important;
      align-items: flex-end;
    `}
    width: ${textWidth ? `${textWidth}px` : '100%'};
    text-overflow: ellipsis;
    overflow: hidden;
    ${!linesCount && `
        white-space: nowrap;
    `}
  `;

  const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
    if (textContainer) {
      const { scrollHeight, clientHeight, scrollWidth, offsetWidth } = textContainer;
      return (
        scrollWidth > offsetWidth
        || scrollHeight > clientHeight
      );
    }
    return false;
  };

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true);
      return;
    }

    setOverflowActive(false);
  }, [isSingleline]);

  useEffect(() => {
    if (overflowingText.current && text) {
      const { scrollHeight, clientHeight } = overflowingText.current;
      setIsSingleLine(scrollHeight <= clientHeight);
    }
  }, [text]);

  return (
    <NvTooltip enabled={isOverflowActive} text={text} preventOverflow={false}>
      <div
        css={styles}
        className={className}
        ref={overflowingText}
      >
        {text}
      </div>
    </NvTooltip>
  );
};

export default NvTruncatedTextWithTooltip;
