import t from 'react-translate';
import { useMentoringProgramQuestionsAnswers } from 'athena/hooks/use-mentoring-program-questions-answers';
import { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { ProgramProfileQuestionType } from 'redux/actions/mentoring-program-profile-questions';
import { MentorshipProgramProfileAnswer } from 'redux/schemas/models/mentoring-program-profile-answers';
import { css } from '@emotion/react';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { ProgramLevelQuestion } from 'redux/schemas/models/mentoring-program-profile-questions';
import { QuestionsProps } from '../types';
import Question from '../question';

const styles = css`
  .program-level-fields {
    gap: ${standardSpacing}px;
  }
`;

const ProgramQuestions = ({
  enrollmentId,
  mentorshipProgramId,
}: QuestionsProps) => {
  const { ref, inView: isEndReached } = useInView();

  const {
    questions,
    loadMore,
  }: {
    questions: MentorshipProgramProfileAnswer[];
    loadMore: () => void;
  } = useMentoringProgramQuestionsAnswers({
    type: ProgramProfileQuestionType.PROGRAM_LEVEL,
    mentorshipProgramId,
    enrollmentId,
  });

  const programLevelFields = useMemo(
    () => (
      <div className='d-flex flex-column program-level-fields'>
        {Object.values(questions || {}).map(question => {
          const { id, isRequired, profileQuestion, answer } = question;
          const { questionList } = profileQuestion as ProgramLevelQuestion;
          const { questionText, type, responseOptions } = questionList || {};
          return (
            <Question
              id={id}
              key={id}
              type={type}
              answer={answer}
              title={questionText}
              isRequired={isRequired}
              options={responseOptions.map(option => option.optionContent)}
            />
          );
        })}
      </div>
    ),
    [questions],
  );

  useEffect(() => {
    if (isEndReached) {
      loadMore();
    }
  }, [isEndReached, loadMore]);

  return (
    questions?.length ? (
      <div css={styles}>
        <div className='heading-4 mt-5'>
          {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.PROGRAM_QUESTIONS.TITLE()}
        </div>
        <div className='text-body mt-1 mb-5'>
          {t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.PROGRAM_QUESTIONS.DESCRIPTION()}
        </div>
        {programLevelFields}
        <div ref={ref} />
      </div>
    ) : null
  );
};

export default ProgramQuestions;
