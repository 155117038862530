import t from 'react-translate';
import AthenaTextInput from 'athena/components/text-input';

type QuestionTextProps = {
  text: string;
  onChange: (event) => void;
};
const QuestionText = ({ text, onChange }: QuestionTextProps) => (
  <AthenaTextInput
    required
    withForm
    value={text}
    showLabel={false}
    name='questionText'
    className='align-content-center'
    onBlur={onChange}
    onKeyDown={(e) => {
      if (e.key === 'Enter') e.preventDefault();
    }}
    placeholder={t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.PROGRAM_LEVEL_FIELDS.PLACEHOLDERS.QUESTION_TEXT()}
  />
);

export default QuestionText;
