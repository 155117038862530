import React, { useEffect, useState } from 'react';
import { config } from '@config/pendo.config.json';
import debounce from 'lodash/debounce';
import t from 'react-translate';
import { ReactComponent as LightGrayAvatar } from 'styles/icons/light-gray-avatar.svg';

// Styles
import { css } from '@emotion/react';
import { darkGray, alto } from 'athena/styles/colors';
import { hexToRgbaString } from 'styles/global_defaults/colors';
import { doubleSpacing, quarterSpacing } from 'styles/global_defaults/scaffolding';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { MentoringProgramRole, MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramEnrollments, refreshProgramParticipantsView } from 'redux/actions/mentoring-program-enrollments';
import { getMentoringProgramEnrollmentsList, getProgramParticipantsViewRefreshState } from 'redux/selectors/mentorship-program-enrollments';

// Hooks
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';
import { usePaginatedApi } from 'shared/hooks/use-paginated-api';
import { useParticipantsSummaryCounts } from 'athena/hooks/use-program-summary-counts';

// Context
import ParticipantsContext from 'athena/components/mentoring-program/participants/context';
import MentoringProgramContext, { MentoringProgramHomeUIContext } from 'athena/components/mentoring-program/context';

// Components
import ParticipantRow from 'athena/components/mentoring-program/participants/participant-row';
import AddParticipantsModal from 'athena/components/mentoring-program/participants/add-participants-modal';
import LoadingRow from 'athena/components/mentoring-program/loading-row';
import NvTable from 'shared/components/nv-table';
import NvNoResults from 'shared/components/nv-no-results-panel';
import NvDropdown, {
  NvDropdownOption,
  NvDropdownButtonStyle,
  NvDropdownAlign,
} from 'shared/components/inputs/nv-dropdown';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import AthenaTextInput, { AthenaTextInputType } from 'athena/components/text-input';
import BulkUploadModal from './bulk-upload-modal';
import NoResultsFound from './no-results-found';
import ParticipantTabs from './participant-tabs';

import { PageSizeParams } from '../constants';

const ROW_HEIGHT = 70;

type Props = {
  scrollEnabled: boolean;
  onScroll: React.ComponentProps<'div'>['onScroll'];
};

const Participants = (props: Props) => {
  const { onScroll, scrollEnabled } = props;

  const dispatch = useAppDispatch();
  const scrollRef = React.useRef<HTMLDivElement>();
  const countState = useParticipantsSummaryCounts();
  const [searchTerm, setSearchTerm] = React.useState('');
  // 70 is the height of each row, so we detect when there is less than 3 rows
  const reachedEnd = useInfiniteScroll(scrollRef.current, ROW_HEIGHT * 3);
  const [showAddParticipants, setShowAddParticipants] = React.useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const { withOverflowFlexGrowClass } = React.useContext(MentoringProgramHomeUIContext);
  const { countState: programCountState, mentoringProgram } = React.useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));
  const [roleFilter, setRoleFilter] = React.useState<MentoringProgramRole>(MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR);
  const refreshViewState = useSelector(getProgramParticipantsViewRefreshState);
  const [noResultsForAnyTab, setNoResultsForAnyTab] = useState(false);

  const [mentorsSorting, setMentorSorting] = React.useState([{
    id: 'name',
    desc: false,
  }]);
  const [menteeSorting, setMenteeSorting] = React.useState([{
    id: 'name',
    desc: false,
  }]);
  const [adminSorting, setAdminSorting] = React.useState([{
    id: 'name',
    desc: false,
  }]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchTerm = React.useCallback(debounce(setSearchTerm, 500), []);

  const manageUsersButtonItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.MENTORING_PROGRAMS.MANUALLY_ADD_USERS(),
      callback: () => setShowAddParticipants(true),
      dataQa: config.pendo.athena.mentorshipProgram.showAddParticipants,
    },
    {
      type: 'text',
      text: t.MENTORING_PROGRAMS.BULK_UPLOAD(),
      callback: () => setShowBulkUploadModal(true),
      dataQa: config.pendo.athena.mentorshipProgram.showBulkUpload,
    },
  ];

  const roleFiltersMeta = {
    [MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN]: {
      count: countState.result?.mentorshipProgramAdmin,
      title: t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.ADMINS(),
      filterTitle: t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.NUM_ADMINS(countState.result?.mentorshipProgramAdmin),
      sorting: adminSorting,
      setSorting: setAdminSorting,
    },
    [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE]: {
      count: countState.result?.mentorshipProgramMentee,
      title: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTEES(mentoringProgramAliases),
      filterTitle: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.NUM_MENTEES({
        menteesCount: countState.result?.mentorshipProgramMentee,
        ...mentoringProgramAliases,
      }),
      sorting: menteeSorting,
      setSorting: setMenteeSorting,
    },
    [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR]: {
      count: countState.result?.mentorshipProgramMentor,
      title: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTORS(mentoringProgramAliases),
      filterTitle: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.NUM_MENTORS({
        mentorsCount: countState.result?.mentorshipProgramMentor,
        ...mentoringProgramAliases,
      }),
      sorting: mentorsSorting,
      setSorting: setMentorSorting,
    },
  };

  const params = React.useMemo(() => {
    const sortingObject: any = {};

    const currentSorting = roleFiltersMeta[roleFilter].sorting[0];

    if (currentSorting) {
      const { id, desc } = currentSorting;

      sortingObject.sortBy = id;
      sortingObject.sortDesc = desc;
    }

    return {
      pageSize: 20,
      textSearch: searchTerm,
      courseRoleName: roleFilter,
      updateCounts: true,
      ...sortingObject,
    };
  }, [roleFiltersMeta[roleFilter].sorting, searchTerm, roleFilter]);

  const {
    reset,
    result,
    loadMore,
    isLoading,
  } = usePaginatedApi<MentoringProgramEnrollment, PageSizeParams>(
    (p) => dispatch(getMentoringProgramEnrollments({
      programId: mentoringProgram.id,
      ...p,
    })).then((action) => action.payload),
    params,
    getMentoringProgramEnrollmentsList,
  );

  const styles = css`
    .search-input {
      flex: 1;

      input.search-input-element {
        font-weight: 300;
        // Search input in mentoring program has different height to match
        // button height
        height: ${doubleSpacing + quarterSpacing}px;
      }
    }

    .manage-users-btn {
      height: 45px;
    }

    .table-body {
      overflow-y: ${scrollEnabled ? 'auto' : 'hidden'};

      .empty-ui {
        height: 250px;
        color: ${darkGray};
        background-color: ${hexToRgbaString(alto, 0.2)};
      }
    }
  `;

  const getIsPillSelected = (role: MentoringProgramRole) => role === roleFilter;

  const contextValue = {
    reset,
    countState,
    roleFilter,
  };

  useEffect(() => {
    if (reachedEnd) {
      loadMore();
    }
  }, [loadMore, reachedEnd]);

  useEffect(() => {
    if (refreshViewState) {
      reset();
      countState.refresh();
      programCountState.refresh();
      dispatch(refreshProgramParticipantsView({ refreshView: false }));
    }
  }, [refreshViewState]);

  useEffect(() => {
    setNoResultsForAnyTab(
      !!searchTerm
      && countState.result
      && countState.result.mentorshipProgramAdmin === 0
      && countState.result.mentorshipProgramMentee === 0
      && countState.result.mentorshipProgramMentor === 0,
    );
  }, [searchTerm, countState.result]);

  return (
    <ParticipantsContext.Provider value={contextValue}>
      <div css={styles} className={`d-flex flex-column ${withOverflowFlexGrowClass}`}>
        <AddParticipantsModal
          show={showAddParticipants}
          onClose={(added) => {
            if (added) {
              reset();
              countState.refresh();
              programCountState.refresh();
            }

            setShowAddParticipants(false);
          }}
        />
        <div className='d-flex mb-4'>
          <AthenaTextInput
            icon='search'
            type={AthenaTextInputType.SEARCH}
            value={searchTerm}
            showLabel={false}
            className='search-input mr-3'
            inputClassName='search-input-element'
            onChange={(e) => debouncedSetSearchTerm(e.target.value)}
            placeholder={t.MENTORING_PROGRAMS.CONNECTIONS.SEARCH_PLACEHOLDER()}
          />
          <NvDropdown
            buttonClass='manage-users-btn'
            items={manageUsersButtonItems}
            buttonStyle={NvDropdownButtonStyle.BUTTON}
            align={NvDropdownAlign.RIGHT}
            title={t.USER_MANAGEMENT.MANAGE_USERS()}
            toggleDataQa={config.pendo.athena.mentorshipProgram.manageUsers}
          />
        </div>
        {noResultsForAnyTab ? (
          <NvNoResults
            action={() => setSearchTerm('')}
            hideClearSearch={false}
            clearText={t.SEARCH.CLEAR()}
            noResultsText={t.SEARCH.NO_RESULTS_FOUND()}
          />
        ) : (
          <>
            {countState.result && (
              <ParticipantTabs
                setRoleFilter={setRoleFilter}
                roleFilter={roleFilter}
                roleFiltersMeta={roleFiltersMeta}
              />
            )}
            <div className='d-flex align-items-center justify-content-between'>
              <div className='heading-3'>
                {roleFiltersMeta[roleFilter].title}
              </div>
              <div>
                {(roleFiltersMeta[roleFilter].count !== undefined) && t.MENTORING_PROGRAMS.PARTICIPANTS.X_TOTAL_PARTICIPANTS(roleFiltersMeta[roleFilter].count)}
              </div>
            </div>
            <NvTable<MentoringProgramEnrollment>
              data={result ?? []}
              onSortingChange={roleFiltersMeta[roleFilter].setSorting}
              RowComponent={ParticipantRow}
              className={`d-flex flex-column ${withOverflowFlexGrowClass}`}
              state={{
                sorting: roleFiltersMeta[roleFilter].sorting,
              }}
              renderAfterRows={(afterRowsProps) => {
                if (result !== null && !result.length) {
                  return searchTerm ? (
                    <NoResultsFound />
                  ) : (
                    <div className='d-flex flex-column align-items-center justify-content-center empty-ui'>
                      <LightGrayAvatar width={80} height={80} className='mb-3' />
                      <div className='text-medium'>
                        {t.MENTORING_PROGRAMS.PARTICIPANTS.EMPTY({
                          role: roleFilter,
                          ...mentoringProgramAliases,
                        })}
                      </div>
                    </div>
                  );
                }

                return isLoading && <LoadingRow {...afterRowsProps} />;
              }}
              bodyProps={{
                onScroll,
                ref: scrollRef,
                className: `table-body ${withOverflowFlexGrowClass}`,
              }}
              columns={[
                {
                  id: 'name',
                  enableSorting: true,
                  gridTemplateColumn: '3fr',
                  accessorFn: (row) => row.user,
                  header: t.COURSE_ADMIN_DASHBOARD.TABLE.NAME(),
                },
                !getIsPillSelected(MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN) && {
                  id: 'status',
                  enableSorting: true,
                  accessorFn: (row) => row,
                  gridTemplateColumn: '2fr',
                  header: t.MENTORING_PROGRAMS.PARTICIPANTS.TABLE.STATUS(),
                },
                !getIsPillSelected(MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN) && {
                  id: 'assigned',
                  enableSorting: true,
                  gridTemplateColumn: '5fr',
                  accessorFn: (row) => row.assignedConnections,
                  header: getIsPillSelected(MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR) ? t.MENTORING_PROGRAMS.PARTICIPANTS.TABLE.ASSIGNED_MENTEES(mentoringProgramAliases) : t.MENTORING_PROGRAMS.PARTICIPANTS.TABLE.ASSIGNED_MENTOR(mentoringProgramAliases),
                },
                {
                  header: '',
                  id: 'options',
                  gridTemplateColumn: '56px',
                  accessorFn: (row) => row.userId,
                },
              ].filter(Boolean)}
            />
          </>
        )}

        <NvModal
          type={ModalType.FIXED}
          header={t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.TITLE()}
          body={<BulkUploadModal onClose={() => setShowBulkUploadModal(false)} />}
          show={showBulkUploadModal}
          onClose={() => setShowBulkUploadModal(false)}
          height='unset'
        />
      </div>
    </ParticipantsContext.Provider>
  );
};


export default Participants;
