/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-return-assign */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback,
} from 'react';
import t from 'react-translate';
import { config } from '@config/pendo.config.json';
import { getSettingsSaveStatus } from 'redux/selectors/mentoring-programs';
import { useQuery } from 'shared/hooks/use-query';
import { AngularContext } from 'react-app';
import { useSelector } from 'react-redux';
import useMentoringProgramRole from 'athena/hooks/use-mentoring-program-role';

import { css } from '@emotion/react';
import { gray2, gray5 } from 'styles/global_defaults/colors';
import { textMediumFontSize } from 'styles/global_defaults/fonts';
import { almostBlack, shadedWhite } from 'athena/styles/colors';
import {
  extraLargeSpacing,
  halfSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

import ResponsiveTabs, {
  Orientation,
  Tab as TabType,
} from 'athena/components/responsive-tabs';
import NvIcon from 'shared/components/nv-icon';
import ClickableContainer from 'components/clickable-container';
import ProgramSetup from './program-setup';
import SaveToast from './save-toast';
import ProgramProfileSetup from './program-profile-setup';
import MatchingSettings from './matching-settings';

type Tab = TabType & {
  allowed: boolean;
  Component: React.ComponentType;
};

const autoScrollToElement = (element, callback) => {
  const observer = new IntersectionObserver((entries, obs) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setTimeout(() => {
        callback();
      }, 400);
      obs.disconnect();
    }
  });

  observer.observe(element);
  element.scrollIntoView({ behavior: 'smooth' });
};

const styles = css`
  flex: 1;

  .settings-header {
    height: 65px;
    flex: 0 0 auto;
    border-bottom: 1px solid ${gray5};
  }
  .settings-content {
    flex: 1;
    overflow: hidden;
    padding-top: ${extraLargeSpacing}px;

    .side-tabs {
      flex: 0 0 320px;
      padding: 0 ${standardSpacing}px ${extraLargeSpacing}px;
      overflow-y: auto;

      .tabs-container {
        border-left: 1px solid ${shadedWhite};
      }
    }
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: ${tripleSpacing}px;
      overflow-y: scroll;
      padding: 0 ${halfSpacing}px;
      position: relative;

      .title {
        color: ${almostBlack};
      }

      form {
        .input-label {
          font-size: ${textMediumFontSize}px;
          color: ${gray2};
        }
      }
    }
  }
`;

const ProgramConfiguration = () => {
  const { injectServices, $scope } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { isAdmin } = useMentoringProgramRole();
  const isMounted = useRef(false);

  useEffect(() => {
    // Directly checking against `false`, because this will be `null` before the role is loaded
    if (isAdmin === false) {
      $state.go('mentoring-program-root', {
        programId: $state.params.programId,
      });
    }
  }, [isAdmin, $state]);

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: 'program',
        allowed: isAdmin,
        Component: ProgramSetup,
        text: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_SETUP.TITLE(),
        dataQa: config.pendo.athena.mentorshipProgram.settings.tabs.programSetup,
      },
      {
        id: 'profile',
        allowed: isAdmin,
        Component: ProgramProfileSetup,
        text: t.MENTORING_PROGRAMS.SETTINGS.TABS.PROGRAM_PROFILE_SETUP.TITLE(),
        dataQa: config.pendo.athena.mentorshipProgram.settings.tabs.programProfileSetup,
      },
      {
        id: 'matching',
        allowed: isAdmin,
        Component: MatchingSettings,
        text: t.MENTORING_PROGRAMS.SETTINGS.TABS.MATCHING_SETTINGS.TITLE(),
        dataQa: config.pendo.athena.mentorshipProgram.settings.tabs.matchingSettings,
      },
    ],
    [isAdmin],
  );
  const { tab: activeTab } = useQuery();
  const [currentTab, setCurrentTab] = useState<string>(
    activeTab || tabs.find(tab => tab.allowed)?.id,
  );
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [scrolling, setScrolling] = useState(false);
  const saveStatus = useSelector(getSettingsSaveStatus);

  const handleGoBack = () => {
    const previousState = $scope.StateManager.previousStatesEntered[$scope.StateManager.previousStatesEntered.length - 1];
    if (previousState) {
      return $state.go(previousState, $scope.StateManager.previousParamsEntered[$scope.StateManager.previousParamsEntered.length - 1]);
    }
    return $state.go('mentoring-program-root', {
      programId: $state.params.programId,
    });
  };

  const onSelectTab = (selectedTab: string) => {
    setScrolling(true);
    setCurrentTab(selectedTab);
    setTimeout(() => {
      setScrolling(false);
    }, 500);
  };

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {
    if (currentTab) {
      const sectionElem = sectionRefs.current[currentTab];
      if (sectionElem) {
        if (isMounted.current) {
          sectionElem.scrollIntoView({ behavior: 'smooth' });
        } else {
          setScrolling(true);
          setTimeout(() => {
            autoScrollToElement(sectionElem, () => {
              setScrolling(false);
            });
          }, 700);
        }
      }
    }
    isMounted.current = true;
  }, [currentTab]);

  const calculateThreshold = useCallback(() => {
    const maxThreshold = 0.75;
    const minThreshold = 0.35;
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1280) {
      return minThreshold;
    }
    if (screenWidth >= 2560) {
      return maxThreshold;
    }
    return (
      minThreshold
      + ((screenWidth - 1280) / (2560 - 1280)) * (maxThreshold - minThreshold)
    );
  }, []);

  useEffect(() => {
    const threshold = calculateThreshold();
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold,
    };
    const observerCallback = entries => {
      if (scrolling) return;
      let mostVisibleEntry = null;
      let highestVisibilityRatio = 0;
      entries.forEach(entry => {
        if (entry.intersectionRatio > highestVisibilityRatio) {
          highestVisibilityRatio = entry.intersectionRatio;
          mostVisibleEntry = entry;
        }
      });
      if (mostVisibleEntry && mostVisibleEntry.isIntersecting) {
        const newCurrentTab = mostVisibleEntry.target.getAttribute('data-tab');
        if (newCurrentTab !== currentTab) {
          setCurrentTab(newCurrentTab);
        }
      }
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );
    Object.values(sectionRefs.current).forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      Object.values(sectionRefs.current).forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [currentTab, scrolling, calculateThreshold]);

  return (
    <div css={styles} className='d-flex flex-column'>
      <div className='d-flex align-items-center justify-content-between pl-4 pr-4 settings-header'>
        <div className='d-flex'>
          <ClickableContainer onClick={handleGoBack}>
            <NvIcon icon='back' size='small' />
          </ClickableContainer>
          <div className='heading-4 ml-4'>
            {t.MENTORING_PROGRAMS.SETTINGS.TITLE()}
          </div>
        </div>
        {saveStatus ? <SaveToast status={saveStatus} /> : null}
      </div>
      <div className='d-flex settings-content'>
        <div className='side-tabs'>
          <ResponsiveTabs
            tabs={tabs.filter(tab => tab.allowed)}
            selectedTab={currentTab}
            orientation={Orientation.VERTICAL}
            onChange={onSelectTab}
          />
        </div>
        <div className='content w-100'>
          {tabs.map(({ id, Component }) => (
            <div
              key={id}
              ref={el => (sectionRefs.current[id] = el)}
              data-tab={id}
            >
              <Component />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramConfiguration;
