/* @ngInject */
export default function NvTimelineSubsectionHeader(
  _,
  TimelinesManager,
  CurrentCourseManager,
  CurrentUserManager,
  FilterCoursesManager,
  $uibModal,
  nvUtil,
  ConfirmationOverlays,
  config,
) {
  return {
    scope: {
      lectureSubsection: '=',
      updateLectureSection: '&?',
      showLessonsLibrary: '&?',
      hasLibraryLessons: '=?',
      parent: '=?',
      indexOfParent: '=?',
      index: '=?',
      copyToStatus: '<?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;
      vm.TimelinesManager = TimelinesManager;
      vm.CurrentCourseManager = CurrentCourseManager;
      vm.CurrentUserManager = CurrentUserManager;
      vm.formId = nvUtil.randomPositiveInteger();
      vm.isCopyToDropdownOpen = false;
      vm.lectureSubsection.copyToStatus = vm.parent?.copyToStatus;
      vm.config = config;

      vm.$onChanges = (changes) => {
        vm.lectureSubsection.copyToStatus = vm.parent?.copyToStatus;
      };

      vm.contentType = 'SUBSECTION';
      vm.content = vm.lectureSubsection;

      const filterCurrentCourse = ({ catalogId, isJourney, isContentManagementCollection }) => catalogId !== CurrentCourseManager.course.catalogId && !isJourney && isContentManagementCollection;
      const activeOfferings = FilterCoursesManager.coursesForTab(_.filter(CurrentUserManager.courses, filterCurrentCourse), 'active');
      const futureOfferings = FilterCoursesManager.coursesForTab(_.filter(CurrentUserManager.courses, filterCurrentCourse), 'future');
      const demoOfferings = FilterCoursesManager.coursesForTab(_.filter(CurrentUserManager.courses, filterCurrentCourse), 'demo');
      vm.categories = [futureOfferings, activeOfferings, demoOfferings];
      vm.hasCourses = _.some(vm.categories, (c) => c.length);

      vm.lastCopyToOfferingVisible = function () {
        return vm.CurrentUserManager.lastCopyToOffering && (CurrentCourseManager.course.catalogId !== vm.CurrentUserManager.lastCopyToOffering);
      };
      vm.openCopyToDropdown = function () {
        vm.isCopyToDropdownOpen = true;
      };

      vm.updateCopyStatus = function (status) {
        vm.lectureSubsection.copyToStatus = status;
      };

      vm.findLastCopiedOffering = function () {
        let lastCopiedOffering = null;
        _.each(CurrentUserManager.courses, (course) => {
          if (course.catalogId === vm.CurrentUserManager.lastCopyToOffering) {
            lastCopiedOffering = course;
          }
        });
        return lastCopiedOffering;
      };

      vm.copyLastSelected = function () {
        vm.isCopyToDropdownOpen = false;
        const course = vm.findLastCopiedOffering();
        const { content, contentType, updateCopyStatus } = vm;
        vm.uibModalInstance = $uibModal.open({
          backdrop: 'static',
          templateUrl: 'timelines/templates/copy-to-offering-modal.html',
          controller: 'CopyToOfferingModalCtrl as vm',
          windowClass: 'copy-to-modal',
          resolve: {
            course,
            content,
            type: () => contentType,
            updateCopyStatus: () => updateCopyStatus.bind(vm),
          },
        });
      };

      vm.updateDeadlines = function () {
        const modal = $uibModal.open({
          templateUrl: 'timelines/templates/bulk-edit-deadline-modal.html',
          controller: 'BulkEditDeadlineCtrl as vm',
          windowClass: 'full-screen-modal-handheld full-screen-modal-tablet bulk-edit-deadline-modal',
          resolve: {
            section: vm.lectureSubsection,
          },
        });
      };

      vm.updateAllReleaseDates = function () {
        const modal = $uibModal.open({
          templateUrl: 'timelines/templates/bulk-edit-release-dates-modal.html',
          controller: 'BulkEditReleaseDatesCtrl as vm',
          windowClass: 'full-screen-modal-handheld full-screen-modal-tablet bulk-edit-deadline-modal',
          resolve: {
            section: vm.lectureSubsection,
          },
        });
      };

      vm.deleteSection = function () {
        const lectureCount = vm.lectureSubsection.lecturePages.length;

        if (lectureCount) {
          const modalInstance = ConfirmationOverlays.openConfirmationModal('timelines/templates/modal-delete-section-confirmation.html',
            'AttachModalResolvesToVmCtrl', {
              vmResolves() {
                return {
                  lectureCount,
                  isSubSection: true,
                  section: vm.lectureSubsection,
                  CurrentCourseManager,
                };
              },
            });

          modalInstance.result.then(() => {
            vm.TimelinesManager.removeLectureSection(vm.lectureSubsection.id, 'LectureSubsection', vm.parent.id ? vm.parent : null, false);
          });
        } else {
          vm.TimelinesManager.removeLectureSection(vm.lectureSubsection.id, 'LectureSubsection', vm.parent.id ? vm.parent : null, false);
        }
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'timelines/templates/nv-timeline-subsection-header.html',
  };
}
